import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { ImportButton } from 'components/ui/ImportButton';
import { NewCampaignUploadModal } from 'components/ui/NewCampaignUploadModal';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks';
import { resetInit } from 'features/authSlice';


interface ParentImportFileComponentProps {
  ButtonText: string;
  isCampaignExist: boolean;
}

export const ParentImportFileComponent: React.FC<ParentImportFileComponentProps> = (
  { ButtonText, isCampaignExist }) => {
  const dispatch = useAppDispatch();
  const analyseLoading = useAppSelector((state) => state.company.loadingAnalyse);
  const [isNewCampaignModalOpen, setIsNewCampaignModalOpen] = useState(false);

    useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const type = urlParams.get('type');
      const code = urlParams.get('code');
      if (type === 'hubspot' && code && !isNewCampaignModalOpen) {
        setIsNewCampaignModalOpen(true);
      }
    }, []);
  

  const openNewCampaignModal = () => {
    setIsNewCampaignModalOpen(true);
  };
  
  const onCompleteCamign = () => {
    setIsNewCampaignModalOpen(false)
  };

  const onCloseModal = () => {
    setIsNewCampaignModalOpen(false)
    dispatch(resetInit());
  }

  return (
    <Box>
      <Box sx={{ cursor: analyseLoading === 'hubspot' ? "not-allowed" : 'default'}}>
        <ImportButton onClick={openNewCampaignModal} ButtonText={ButtonText} disabled={analyseLoading === 'hubspot'} />
      </Box>
      <NewCampaignUploadModal 
        onComplete={onCompleteCamign}
        open={isNewCampaignModalOpen} 
        onClose={onCloseModal}
        isCampaignExist={isCampaignExist}
      />
    </Box>
  );
};
