import React from 'react';
import { Box } from '@mui/material'; 


export const HubspotImg: React.FC = () => {
  return (
    <Box sx={{ maxWidth: '100%', display: 'flex', justifyContent: 'center' }}>
      <img src={require("../../assets/images/hubspot.png")} alt="anno" style={{ width: '18px', height: 'auto' }} />
    </Box>
  );
}
