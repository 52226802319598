import React from 'react';
import type { SVGProps } from 'react';

export function DataPersonImg(props: SVGProps<SVGSVGElement>) {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.5 7C21.5 9.21 17.47 11 12.5 11C7.53 11 3.5 9.21 3.5 7M21.5 7C21.5 4.79 17.47 3 12.5 3C7.53 3 3.5 4.79 3.5 7M21.5 7V11M3.5 7V12M3.5 12C3.5 14.21 7.5 16 12.5 16M3.5 12V17C3.5 19.21 8 21 10.5 21" stroke="#1C3C6C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M23 20.6123C23 19.0329 19.8294 18.2373 18.25 18.2373C16.6706 18.2373 13.5 19.0329 13.5 20.6123V21.7998H23V20.6123Z" fill="#1C3C6C"/>
        <path d="M20.625 14.6748C20.625 15.9929 19.5681 17.0498 18.25 17.0498C17.6201 17.0498 17.016 16.7996 16.5706 16.3542C16.1252 15.9088 15.875 15.3047 15.875 14.6748C15.875 14.0449 16.1252 13.4408 16.5706 12.9954C17.016 12.55 17.6201 12.2998 18.25 12.2998C19.5681 12.2998 20.625 13.3567 20.625 14.6748Z" fill="#1C3C6C"/>
        </svg>
        );
}