import React, { useState } from 'react';
import { Box } from '@mui/material';
import { ClearDataButton } from 'components/ui/ClearDataButton';
import { ClearDataModal } from 'components/ui/ClearDataModal';
import { useAppSelector } from 'hooks/useReduxHooks';


export const ParentClearDataComponent: React.FC = () => {
  const [isClearDataModalOpen, setClearDataModalOpen] = useState(false);
  const analyseLoading = useAppSelector((state) => state.company.loadingAnalyse);
  
  const openNewCampaignModal = () => {
    setClearDataModalOpen(true);
  };

  return (
    <Box>
      <Box sx={{ cursor: analyseLoading === 'import' || analyseLoading === 'hubspot' ? "not-allowed" : 'default'}}>
        <ClearDataButton onClick={openNewCampaignModal}  disabled={analyseLoading === 'import' || analyseLoading === 'hubspot'} />
      </Box>
      <ClearDataModal 
        open={isClearDataModalOpen} 
        onClose={() => setClearDataModalOpen(false)}
      />
    </Box>
  );
};
