import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { CustomButton } from './CustomButton';
import { useAppSelector } from 'hooks/useReduxHooks';
import { CircularProgress } from '@mui/material';


interface ImportButtonProps {
  onClick: () => void;
  ButtonText: string;
  disabled: boolean;
}

export const ImportButton: React.FC<ImportButtonProps> = ({ onClick, ButtonText, disabled }) => {
  const customSize = ButtonText === 'Add company and contacts data' ? 'xl' : 'l';
  const analyseLoading = useAppSelector((state) => state.company.loadingAnalyse);

  return (
    <CustomButton onClick={onClick} 
                  customSize={customSize}  
                  type="submit"
                  customColor="#F16300"
                  disabled={disabled} 
                  customGradientColor="#FE8836">
        {analyseLoading === 'import' ? 
          (  <CircularProgress size={20} sx={{ color: '#FFFFFF'  }} />) : (
             <AddIcon fontSize='small'/> 
          )
          }
      
      {ButtonText}
    </CustomButton>
  );
};
