import React from 'react';
import type { SVGProps } from 'react';

export function BigDataOutlined(props: SVGProps<SVGSVGElement>) {
	return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.5004 6.14702C20.2863 6.0971 20.0692 6.06136 19.8504 6.04002C19.5496 4.51624 18.7827 3.12363 17.6557 2.05485C16.5287 0.986076 15.0974 0.293983 13.5598 0.0743292C12.0222 -0.145324 10.4544 0.118322 9.07324 0.82879C7.69207 1.53926 6.56586 2.66142 5.85042 4.04002C5.38936 4.09574 4.93633 4.2048 4.50042 4.36502C3.3314 4.77535 2.31869 5.53865 1.60224 6.54943C0.885791 7.56022 0.500977 8.76857 0.500977 10.0075C0.500977 11.2465 0.885791 12.4548 1.60224 13.4656C2.31869 14.4764 3.3314 15.2397 4.50042 15.65V13.446C3.89261 13.098 3.38751 12.5957 3.03622 11.9898C2.68493 11.3838 2.49993 10.6959 2.49993 9.99552C2.49993 9.29514 2.68493 8.60719 3.03622 8.00128C3.38751 7.39537 3.89261 6.89301 4.50042 6.54502C4.97796 6.26454 5.50975 6.08897 6.06042 6.03002L7.13042 5.92002L7.63042 4.97002C8.15289 3.95786 8.97741 3.13338 9.9896 2.61097C11.0018 2.08855 12.1515 1.89409 13.2792 2.05456C14.4069 2.21503 15.4567 2.72248 16.283 3.50651C17.1092 4.29054 17.671 5.31229 17.8904 6.43002L18.1904 7.93002L19.7204 8.04002C19.9869 8.06201 20.2492 8.11952 20.5004 8.21102C21.084 8.41167 21.5904 8.78953 21.9489 9.29186C22.3073 9.79418 22.5 10.3959 22.5 11.013C22.5 11.6301 22.3073 12.2319 21.9489 12.7342C21.5904 13.2365 21.084 13.6144 20.5004 13.815V15.899C21.6281 15.6741 22.643 15.0654 23.3723 14.1763C24.1015 13.2873 24.5001 12.1729 24.5001 11.023C24.5001 9.87313 24.1015 8.75879 23.3723 7.86973C22.643 6.98068 21.6281 6.37189 20.5004 6.14702Z" fill="#1C3C6C"/>
        <path d="M18.5 11.6667C18.5 13.14 15.8133 14.3333 12.5 14.3333C9.18667 14.3333 6.5 13.14 6.5 11.6667M18.5 11.6667C18.5 10.1933 15.8133 9 12.5 9C9.18667 9 6.5 10.1933 6.5 11.6667M18.5 11.6667V15M6.5 11.6667V15M6.5 15C6.5 16.4733 9.18667 17.6667 12.5 17.6667M6.5 15V18.3333C6.5 19.8067 9.18667 21 12.5 21" stroke="#1C3C6C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.5 11.6667C6.5 13.14 9.18667 14.3333 12.5 14.3333C15.8133 14.3333 18.5 13.14 18.5 11.6667M6.5 11.6667C6.5 10.1933 9.18667 9 12.5 9C15.8133 9 18.5 10.1933 18.5 11.6667M6.5 11.6667V15M18.5 11.6667V15M18.5 15C18.5 16.4733 15.8133 17.6667 12.5 17.6667M18.5 15V18.3333C18.5 19.8067 15.8133 21 12.5 21" stroke="#1C3C6C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        );
}