import React, { useState, useRef, useEffect } from 'react';
import { TextField, Typography, Box, Stack, CircularProgress, FormHelperText, Divider } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { BaseModal } from './BaseModal';
import { CustomButton } from './CustomButton';
import { HubspotImg } from 'components/images/Hubspot';
import { SalesforceImg } from 'components/images/Salesforce';
import { setAnalysisLoading, clearAnalysisLoading } from 'features/companySlice';
import { useCreateCampaignMutation, useUploadCampaignFileMutation,
  useCreateHubspotImportMutation, useGetHubspotUrlMutation, useCreateHubspotUrlMutation
 } from '../../api/companyApi'
import { useCompany } from '../../hooks/useCompanyHook';
import { useHandleError } from 'hooks/useHandleError';
import { useHandleSuccess } from 'hooks/useHandleSuccess';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks';
import { RootState } from 'app/store';
import { selectIsInit } from 'features/authSlice';
import { sseManager } from 'utils/useSSEEvent';
import { baseUrl } from 'api/baseQuery';

interface NewCampaignUploadModalProps {
  open: boolean;
  onClose: () => void;
  onComplete: () => void;
  isCampaignExist: boolean; 
}
type FieldErrorMap = Record<string, string | undefined>;

export const NewCampaignUploadModal: React.FC<NewCampaignUploadModalProps> = ({ open, onClose, onComplete, isCampaignExist }) => {
  const dispatch = useAppDispatch();
  const { updateCompany } = useCompany();
  const [formData, setFormData] = useState({
    company_name: '',
    company_abstract: '',
    target_audience: '',
  });
  const [currentStep, setCurrentStep] = useState(1);
  const token = useAppSelector((state: RootState) => state.auth.accessToken);
  const refreshToken = useAppSelector((state: RootState) => state.auth.refreshToken);
  const showInitModal = useAppSelector(selectIsInit);
  const campaignId = useAppSelector((state) => state.company.company);
  const [uploadedFile, setUploadedFile] = useState<string | null>(null);
  const [fieldErrors, setFieldErrors] = useState<FieldErrorMap>({});
  const [globalErrors, setGlobalErrors] = useState<string | null>(null);
  const [hubspotErrors, setHubspotErrors] = useState<string | null>(null);
  const [isClosedByComplete, setIsClosedByComplete] = useState(false);
  const [newCampaignid, setNewCamaignid] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [progress, setProgress] = useState<number>(0);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [isModalOpen, setModalOpen] = useState(false);
  
  const [createCampaign, { isSuccess: isCampaignCreated, data: createCampaignResult, isLoading: creatingCampaign, error: campaignError, reset: resetCampaignError }] = useCreateCampaignMutation();
  const [uploadFile, { isSuccess: uploadSuccess, isLoading: uploadingFile, error: fileError, reset: resetFileError }] = useUploadCampaignFileMutation();
  
  const [createHubspotImport, { isLoading: isImporting }] = useCreateHubspotImportMutation();
  const [getHubspotUrl, { isSuccess: urlSuccess, error: urlError, reset: resetUrlError, data: urlHubspotData }] = useGetHubspotUrlMutation();
  const [createHubspotUrl, { isSuccess: hubspotSuccess, isLoading: isConnecting, error: hubspotErrorData, reset: resetHubspotError }] = useCreateHubspotUrlMutation();

  useHandleError(hubspotErrorData, {
    setGlobalErrors: setHubspotErrors,
    reset: resetHubspotError,
  });

  useHandleError(urlError, {
    setGlobalErrors: setHubspotErrors,
    reset: resetUrlError,
  });

  useHandleSuccess(hubspotSuccess, () => {
    dispatch(setAnalysisLoading('import'))
    clearQueryParams();
    if (newCampaignid) {
      handleHubspotIntegration();
    } else {
      setHubspotErrors("Campaign not found, try again")
    }
  });

  useHandleSuccess(urlSuccess, () => {
    if (!urlHubspotData) return;
    window.location.href = urlHubspotData.url;
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const type = urlParams.get('type');
    const code = urlParams.get('code');
    setNewCamaignid(urlParams.get('state') || '');
    if (type === 'hubspot' && code && !isModalOpen) {
      const savedData = localStorage.getItem('formData');
        if (savedData) {
          const parsedData = JSON.parse(savedData);
          setFormData(parsedData);
      }
      setModalOpen(true);
      setCurrentStep(2);
      createHubspotUrl({ code });
    }
  }, []);


  const clearQueryParams = () => {
    const url = new URL(window.location.href);
    url.search = '';
    window.history.replaceState(null, '', url.toString());
  }

  const handleHubspotIntegration = () => {
    setGlobalErrors(null);
    setHubspotErrors(null);
    localStorage.setItem('formData', JSON.stringify(formData));
    createHubspotImport({ campaign_id: newCampaignid })
      .unwrap()
      .then(() => {
        setIsFileUploaded(true);
        dispatch(setAnalysisLoading('import'))
        listenToHubspotSSE(newCampaignid);
      })
      .catch((error) => {
        console.error(error)
        if (error?.status === 400) {
          getHubspotUrl({ campaign_id: newCampaignid })
        } else if(error?.status === 404) {
          setHubspotErrors("Campaign not found")
        } else if(error?.status === 405) {
          createCampaign(formData)
            .unwrap()
            .then((campaignResponse) => {
              setNewCamaignid(campaignResponse.campaign_id);
              handleHubspotIntegration();
            })
            .catch((creationError) => {
              console.error('Company creation error:', creationError);
              setHubspotErrors("Analysis error, try again later");
            });
        } else if(error?.status === 428) {
          setHubspotErrors("CRM does not contain any relevant data")
        } else {
          setHubspotErrors("Analysis error from hubspot, try again later")
        }
      });
  };

  const listenToHubspotSSE = (campaign: string) => {
    if (!token) {
      return;
    }
    
    sseManager.connect({
      url: `${baseUrl}/campaigns/${campaign}/sse-status`,
      token,
      refreshToken,
      onMessage: (data) => {
        const { status, progress } = data;
        setProgress(progress);

        if (status === 'failed') {
          setHubspotErrors('Something went wrong during the analysis. Please try again.');
          setProgress(0);
          setIsFileUploaded(false);
          setUploadedFile(null);
        }

        if (status === 'completed') {
          setTimeout(() => {
            handleCompleteAndClose();
          }, 500);
        }
      },
      onError: (error) => {
        console.error('SSE Error:', error);
      },
      dispatch,
    });

    return () => {
      sseManager.close(); 
    };
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setFieldErrors((prev) => {
      const { [name]: _, ...rest } = prev;
      return rest;
    });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;
    if (event.target.files && event.target.files.length > 0 && file) {
        setUploadedFile(file.name);
        uploadFile({ campaign_id: newCampaignid, file })
    }
  };

  const validateAndOpenFileDialog = () => {
    setError(null);
    fileInputRef.current?.click();
  };


  const validateStepOne = () => {
    const { company_name, company_abstract, target_audience } = formData;
    const newFieldErrors: FieldErrorMap = {};
    if (!company_name) newFieldErrors.company_name = 'Company name is required';
    if (!company_abstract) newFieldErrors.company_abstract = 'Company abstract is required';
    if (!target_audience) newFieldErrors.target_audience = 'Target audience is required';

    setError(null);
    if (Object.keys(newFieldErrors).length > 0) {
      setFieldErrors(newFieldErrors);
      return;
    }
    createCampaign(formData);
  };

  useHandleError(campaignError, {
    setFieldErrors,
    setGlobalErrors,
    reset: resetCampaignError,
  });
  useHandleError(fileError, {
    setFieldErrors,
    setGlobalErrors,
    reset: resetFileError,
  });

  useHandleSuccess(isCampaignCreated, () => {
    if (createCampaignResult?.campaign_id) {
      setNewCamaignid(createCampaignResult.campaign_id);
      setCurrentStep(2);
     
    }
  });

  const handleCancel = () => {
    onClose();
    setCurrentStep(1);
  };

  const handleBack = () => {
    setCurrentStep(1);
  };

  useHandleSuccess(uploadSuccess, () => {
    setIsFileUploaded(true);
    dispatch(setAnalysisLoading('import'))
  });


  useEffect(() => {
    if (isClosedByComplete) {
      setFormData({
        company_name: '',
        company_abstract: '',
        target_audience: '',
      });
      setUploadedFile(null);
      setIsFileUploaded(false);
      setProgress(0);
      setGlobalErrors(null);
      setHubspotErrors(null);
      setFieldErrors({});
      setIsClosedByComplete(false);
      setCurrentStep(1);
    }
  }, [isClosedByComplete]);

  const handleCompleteAndClose = () => {
    dispatch(clearAnalysisLoading())
    updateCompany(newCampaignid);
    setIsClosedByComplete(true);
    onComplete();
    onClose();
  };

  useEffect(() => {
    if (!newCampaignid || !isFileUploaded || !token) {
      return;
    }

    const handleMessage = (data: any) => {
      const { status, progress } = data;
      setProgress(progress);
  
      if (status === 'failed') {
        setGlobalErrors("Something went wrong during the analysis. Please try to upload data again");
        setIsFileUploaded(false);
        setUploadedFile(null);
        setProgress(0);
      }
  
      if (status === 'completed') {
        setTimeout(() => {
          handleCompleteAndClose();
        }, 500);
      }
    };

    const handleError = (error: any) => {
      console.error('SSE Error:', error);
    };

    sseManager.connect({
      url: `${baseUrl}/campaigns/${newCampaignid}/sse-status`,
      token,
      refreshToken,
      onMessage: handleMessage,
      onError: handleError,
      dispatch: dispatch
    });
    
    return () => {
      sseManager.close(); 
    };
  }, [newCampaignid, isFileUploaded, token, onClose]);
  
  useEffect(() => {
    if (isCampaignCreated || uploadSuccess) {
      setGlobalErrors(null);
      setFieldErrors({});
    }
  }, [isCampaignCreated, uploadSuccess]);


  return (
    <BaseModal
      open={open || (showInitModal && isCampaignExist)}
      onClose={onClose}
      title={"New campaign"}
      width={{ xs: '90%', sm: 490, md: 490 }}
    >
      <Stack gap={3}>
        <Typography variant="subtitle1" sx={{ color: "#4C545B" }}>
          Get highly accurate analysis results in 2 quick steps
        </Typography>
        {currentStep === 1 && (
        <Box>
          <Stack gap={1}>
            <Typography 
              sx={{fontWeight: 500, fontSize:"18px", lineHeight: "24px", color: "custom.textDarkBlue"}}>
              Step 1 of 2: Add client’s company info
            </Typography>
            <Typography variant="body1" sx={{ color: "#4C545B" }}>
              Please provide your client's company and product data to build a decision maker’s profile
            </Typography>
          </Stack>
          <Stack gap={4} pt={4}>
            <Box sx={{ position: 'relative', width: '100%' }}>
              <TextField
                fullWidth
                name="company_name"
                placeholder=" e.g., INFUSE"
                label="Client’s company name*"
                value={formData.company_name}
                variant="outlined"
                error={!!fieldErrors.company_name}
                helperText={fieldErrors.company_name}
                onChange={handleInputChange}
              />
              <FormHelperText
                sx={{
                  position: 'absolute',
                  bottom: -20 ,
                  right: 0,
                  textAlign: 'right',
                  fontSize: "12px",
                  lineHeight: "20px",
                  color: formData.company_name.length > 100 ? 'error.main' : '#00000099',
                }}
              >
                {`${formData.company_name.length}/100`}
              </FormHelperText>
            </Box>
            <Box sx={{ position: 'relative', width: '100%' }}>
              <TextField
                fullWidth
                minRows={4}
                maxRows={4}
                label="Client’s abstract*"
                variant="outlined"
                error={!!fieldErrors.company_abstract}
                helperText={fieldErrors.company_abstract}
                name="company_abstract"
                placeholder=" e.g., INFUSE is a global high-performance demand partner delivering demand strategies, programs, and outcomes for the most admired B2B brands"
                value={formData.company_abstract}
                onChange={handleInputChange}
                InputProps={{
                  rows: 4,
                  multiline: true,
                  inputComponent: 'textarea',
                  style: {
                    overflow: 'auto',
                  },
                }}
              />
              <FormHelperText
                sx={{
                  position: 'absolute',
                  bottom: -20 ,
                  right: 0,
                  textAlign: 'right',
                  fontSize: "12px",
                  lineHeight: "20px",
                  color: formData.company_abstract.length > 1000 ? 'error.main' : '#00000099', 
                }}
              >
              {`${formData.company_abstract.length}/1000`}
              </FormHelperText>
            </Box>
            <Box sx={{ position: 'relative', width: '100%' }}>
              <TextField
                fullWidth
                name="target_audience"
                placeholder=" e.g., Demand specialists, marketers, sales, executives. Manager+ seniority. Located in NOAM, EMEA, LATAM, APAC."
                value={formData.target_audience}
                error={!!fieldErrors.target_audience}
                helperText={fieldErrors.target_audience}
                label="Client’s target audience*"
                variant="outlined"
                onChange={handleInputChange}
                InputProps={{
                  rows: 4,
                  multiline: true,
                  inputComponent: 'textarea',
                  style: {
                    overflow: 'auto',
                  },
                }}
              />
              <FormHelperText
                sx={{
                  position: 'absolute',
                  bottom: -20 ,
                  right: 0,
                  textAlign: 'right',
                  fontSize: "12px",
                  lineHeight: "20px",
                  color: formData.target_audience.length > 300 ? 'error.main' : '#00000099',
                }}
              >
              {`${formData.target_audience.length}/300`}
              </FormHelperText>
            </Box>
          </Stack>
        </Box>
         )}
         {currentStep === 2 && (
          <Box>
            <Stack gap={1}>
              <Typography  sx={{fontWeight: 500, fontSize:"17px", lineHeight: "24px", color: "custom.textDarkBlue"}}>
                Step 2 of 2: Upload target company contacts list
              </Typography>
              <Typography variant="body1" sx={{ color: "#4C545B" }}>
                Please choose the way to upload your client's target company contacts list
              </Typography>
            </Stack>
            <Stack pt={3}>
            </Stack>
            <Stack gap={1} sx={{ textAlign: "center" }}>
              {progress === 0 && !isFileUploaded ? (
                <>
                  <Typography variant="body2" color="textSecondary">
                    Max file size: <b>10MB.</b> Preferred format: <b>.csv</b> or <b>Excel file</b>
                  </Typography>
                  <CustomButton
                    variant="contained"
                    customColor="#1C3C6C"
                    fullWidth
                    type='button'
                    onClick={validateAndOpenFileDialog}
                    sx={{ height: "48px" }}
                  >
                    <UploadFileIcon fontSize='medium'/>            
                    Upload from desktop
                    
                  </CustomButton>
                </>
              ) : (
                <Box display="flex" alignItems="center" justifyContent="center" py={1} gap={2}>
                  <CircularProgress  size={20} sx={{ color: 'custom.subTitleModal' }} />
                  <Typography variant='body1' color="text.secondary">
                  {`Analysing${uploadedFile ? ` ${uploadedFile}` : ''}`}: <b>{`${progress}%`}</b>
                  </Typography>
                </Box>
              )}
              <input
                type="file"
                hidden
                ref={fileInputRef}
                onChange={handleFileChange}
              />
              {globalErrors && (
              <Typography color="error" sx={{ }}>
                {globalErrors}
              </Typography>
              )}
      </Stack>
      
      { !isFileUploaded && 
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            margin: "8px 0",
          }}
        >
          <Divider color='#CFCFCF' sx={{ flexGrow: 1 }} />
            <Typography
              variant='button'
              sx={{
                marginX: "8px",
                color: "#4C545B",
              }}
            >
              or
            </Typography>
          <Divider color='#CFCFCF' sx={{ flexGrow: 1 }} />
        </Box>
        <CustomButton
          variant="outlined"
          customColor="#FFFFFF"
          textColor='#1D3E70'
          fullWidth
          onClick={handleHubspotIntegration}
          type='button'
          sx={{ height: "48px", borderColor: "#1D3E70" }}
      >   
           {isImporting ? 
          (  <CircularProgress size={20} sx={{ color: 'custom.subTitleModal' }} />) : (
            <HubspotImg/>   
          )
          }
                    
          Upload from HubSpot
        </CustomButton>
        {hubspotErrors && (
              <Typography color="error" sx={{ pt:1, pb:1, textAlign:'center' }}>
                {hubspotErrors}
              </Typography>
              )}
        <CustomButton
          variant="outlined"
          customColor="#FFFFFF"
          textColor='#1D3E70'
          fullWidth
          type='button'
          sx={{ height: "48px", marginTop: 1, borderColor: "#1D3E70" }}
        >
          <SalesforceImg/>            
          Upload from Salesforce
        </CustomButton>
        </Box>}
      </Box>)}
      

      { !isFileUploaded && 
        <Stack pt={2}>
        {currentStep === 1 && (
          <Stack direction="row" justifyContent="space-between" gap={1}>
            <CustomButton onClick={handleCancel}            
              variant="contained"
              textColor='#4C545B'
              fullWidth
              type='button'
              customColor="#F1F1F1">
              Cancel
            </CustomButton>
            <CustomButton 
              onClick={validateStepOne} 
              variant="contained"
              fullWidth
              type='button'  
              customColor="#1C3C6C" >
              Next Step
            </CustomButton>
          </Stack>
        )}
        {currentStep === 2 && (
            <CustomButton textColor='#4C545B' onClick={handleBack} customColor='#F1F1F1' variant="contained" fullWidth   type='button'>
              Back
            </CustomButton>
        )}
        </Stack>
         }
      </Stack>
    </BaseModal>
  );
};
