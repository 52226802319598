import React from 'react';
import { CustomButton } from './CustomButton';

interface ClearButtonProps {
  onClick: () => void;
  disabled: boolean;
}

export const ClearDataButton: React.FC<ClearButtonProps> = ({ onClick, disabled }) => {
  return (
    <CustomButton onClick={onClick} 
                  customColor='' 
                  sx={{border: "1px solid #1D3E70"}} 
                  textColor="#1D3E70" 
                  customSize='m'
                  disabled={disabled}
                  variant="outlined">
      Clear data
    </CustomButton>
  );
};
