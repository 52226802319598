import React, { useEffect, useMemo, useState } from 'react';
import { Box, Slider, Typography, Stack, Tabs, Tab,  ToggleButton, ToggleButtonGroup, CircularProgress, Tooltip, IconButton, } from '@mui/material';
import Grid from '@mui/material/Grid2';
import GroupsIcon from '@mui/icons-material/Groups';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { useGetUserGampaignQuery } from 'api/companyApi';
import { CompanyInfo } from 'components/features/CompanyInfo';
import { ToggleSwitch } from 'components/ui/ToggleSwitch';
import { ContactStats } from 'components/ui/ContactStats';
import { NoDataPlaceholder } from 'components/features/NoData';
import { DataTable } from 'components/ui/DataTable';
import { StackedBarChart } from 'components/ui/BarChart';
import { ChartTable } from 'components/ui/ChartTable';
import { CommunicationIcon } from 'components/images/CommunicationImg';
import { MaterialSymbolsTable } from 'components/images/TableImg';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { MageChart } from 'components/images/ChartImg';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks';
import { useCompanyData } from 'hooks/useCompanyData';
import { useChartData } from 'hooks/useChartData';
import { useCampaignMembers } from 'hooks/useCompanyMembers';
import { Column } from 'types/genereal';
import { AdGroupOutline } from 'components/images/AdGroipImg';
import { AdGroupFilled } from 'components/images/AdGroupFilled';
import { SortRounded } from 'components/images/SortRoundedImg';
import { useCompany } from 'hooks/useCompanyHook';
import { clearAnalysisStatus } from 'features/companySlice';

export const CompanyView: React.FC = () => {
  const campaignId = useAppSelector((state) => state.company.company);
  const analysisStatus = useAppSelector((state) => state.company.analysisStatus);
  const dispatch = useAppDispatch();
  const { updateCompany, clearCompanyData } = useCompany();

  const { data: userData, isLoading: userLoading, error: userError, refetch: userRefetch, isUninitialized: isUserUninitialized, } = useGetUserGampaignQuery(undefined, {skip: !!campaignId});
  
  const [currentPage, setCurrentPage] = useState(0);
  const [isToogleShow, setToogleShow] = useState(true);
  const { companyInfo, original, enriched, expected, isLoading: companyLoading, isFetching: companyFetching, campaignRefetch, isUninitialized: isMembersUninitialized, } = useCompanyData(campaignId);
  const { chartData, isLoading, refetch: chartRefetch, isUninitialized: isChartUninitialized  } = useChartData(campaignId);
  const { members, totalPages, totalCount, query, updateQuery, isLoading: membersLoading, refetch: membersRefetch,   isUninitialized: isCampaignUninitialized, } = useCampaignMembers({
    campaign_id: campaignId,
    page: currentPage,
    per_page: 10,
    sort: 'committee_probability',
    order: 'desc'
  });
  const [showEnrichedData, setShowEnrichedData] = useState<boolean>(true);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [viewTable, setViewTable] = useState<"table" | "chart">("table");
  const [page, setPage] = useState(currentPage);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortBy, setSortBy] = useState<string>('committee_probability');
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [range, setRange] = useState<number[]>([0, 100]);

  const marks = [
    { value: 0 },
    { value: 20 },
    { value: 40},
    { value: 50 },
    { value: 60 },
    { value: 80 },
    { value: 100 },
  ];


  const handleQueryChange = (query: { page: number; per_page: number; sort: string; order?: 'asc' | 'desc' }) => {
    setPage(query.page);
    setRowsPerPage(query.per_page);
    setSortBy(query.sort);
    setOrder(query.order ?? 'desc');
    updateQuery({ page: query.page, per_page: query.per_page, sort: query.sort, order: query.order });
  };

  useEffect(() => {
    if (campaignId) {
      setPage(currentPage);
      setRowsPerPage(10);
      setSortBy('committee_probability')
      setOrder('desc')
    }
  }, [campaignId]);


  useEffect(() => {
    if (analysisStatus === 'completed') {
      if (campaignId) {
        if (!isUserUninitialized) userRefetch?.();
        if (!isMembersUninitialized) membersRefetch?.();
        if (!isCampaignUninitialized) campaignRefetch?.();
        if (!isChartUninitialized) chartRefetch?.();
        dispatch(clearAnalysisStatus());
      }
    }
  }, [analysisStatus]);


  const isEnrichedDataEmpty = ((enriched.committee_members === 0 || enriched.committee_members === null) && 
  (enriched.cluster_members === 0 || enriched.cluster_members === null))
 

  useEffect(() => {
    if (userData?.campaign_id) {
      updateCompany(userData.campaign_id);
    } else if (userError) {
      clearCompanyData();
    }
  }, [userData, clearCompanyData, updateCompany, campaignId]);


  const handleViewChange = (
    event: React.MouseEvent<HTMLElement>,
    newViewTable: "table" | "chart" | null
  ) => {
    if (newViewTable !== null) {
      setViewTable(newViewTable);
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowEnrichedData(event.target.checked);
  };

  const dataCards = useMemo(() => {
    if (isEnrichedDataEmpty) {
      setToogleShow(false)
     } else {
      setToogleShow(true)
     }
    const getDescriptionAndPrefix = (
      originalValue: number,
      enrichedValue: number,
      format = '',
    ) => {
      const totalValue = originalValue + enrichedValue;
      return showEnrichedData
        ? { description: `${totalValue || '-'}`, imported: originalValue, enriched: enrichedValue}
        : { description: `${originalValue || '-'}` };
    };
  
    const preparedCardData = [
      {
        title: 'Buying commitee members',
        ...getDescriptionAndPrefix(original.committee_members, enriched.committee_members),
        icon: GroupsIcon,
        iconWidth: '36px',
        iconHeight: '36px',
      },
      {
        title: 'Buying cluster members',
        ...getDescriptionAndPrefix(original.cluster_members, enriched.cluster_members),
        icon: PeopleAltIcon,
        iconWidth: '27px',
        iconHeight: '36px',
      },
      
    ];     

    const engagementScore = showEnrichedData
      ? enriched.engagement_score
      : original.engagement_score;

    
    if (engagementScore !== null && engagementScore !== 0) {
      preparedCardData.push({
        title: 'Total engagement score',
        description: engagementScore !== 0 ? `${engagementScore}%` : `-`,
        //@ts-ignore
        icon: CommunicationIcon, //TODO check types
        iconWidth: '51px',
        iconHeight: '49px',
      });
    }
  
    return preparedCardData;
  }, [original, enriched, showEnrichedData]);

  const chartTabData = useMemo(() => {
    const calculatePercentages = (
      expectedVal: number,
      originalVal: number,
      enrichedVal: number,
      minVal: number,
      maxVal: number
    ) => ({
      importedDataPercentage: Number(
        Math.min((originalVal / expectedVal) * 100, 100).toFixed(2)
      ),
      enrichedDataPercentage: Number(
        Math.min(
          Number(
            Math.min((originalVal / expectedVal) * 100, 100).toFixed(2)
          ) + (enrichedVal / expectedVal) * 100,
          100
        ).toFixed(2)
      ),
      expectedSize: expectedVal,
      minSize: minVal, 
      maxSize: maxVal, 
    });
  
    return {
      committee: calculatePercentages(
        expected.committee_members,
        original.committee_members,
        enriched.committee_members,
        expected.committee_range.min,
        expected.committee_range.max
      ),
      cluster: calculatePercentages(
        expected.cluster_members,
        original.cluster_members,
        enriched.cluster_members,
        expected.cluster_range.min,
        expected.cluster_range.max
      ),
    };
  }, [expected, original, enriched]);
  
  const columns: Column[] = [
    { id: 'data_source', label: 'Source', minWidth: "8.5%", sortable: true, iconAsc: AdGroupFilled, iconDesc: AdGroupOutline },
    { id: 'first_name', label: 'First name', minWidth: "9%" },
    { id: 'last_name', label: 'Last name', minWidth: "9%" },
    { id: 'seniority', label: 'Seniority', minWidth: "9.5%" },
    { id: 'job_title', label: 'Job title', minWidth: "21.25%" },
    { id: 'role', label: 'Role', minWidth: "10%" },
    { id: 'committee_probability', label: 'Committee membership probability (%)', minWidth: "11%", sortable: true, iconAsc: SortRounded },
    { id: 'cluster_probability', label: 'Cluster membership probability (%)', minWidth: "10.25%", sortable: true, iconAsc: SortRounded },
    { id: 'engagement_score', label: 'Engagement score (%)', minWidth: "11%", sortable: true, iconAsc: SortRounded },
  ];
  
  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    const [newMin, newMax] = newValue as number[];
    if (newMin >= newMax) return;
    setRange([newMin, newMax]);
    updateQuery({ min_percent: newMin, max_percent: newMax, page: currentPage });
    setPage(currentPage);
  };

  if (companyLoading || userLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        width="100%"
      >
        <CircularProgress size={80} sx={{ color: 'custom.subTitleModal' }} />
      </Box>
    );
  }

  return (
    <>
      {campaignId ? (
        <Stack sx={{ px: {sm: 8, md: 8, lg:14, xl: 20}, pt: 6, pb: 20, gap: 7 }}>
          <Stack gap={4}>
            <Box sx={{
              flexDirection: 'row', display: "flex", gap: 1
            }}>
              <Typography variant="h4" color="custom.textDarkBlue">
                General information 
              </Typography>
              {isEnrichedDataEmpty ? (
              <Tooltip 
                placement="bottom" 
                arrow
                title="Approximation based the imported data"
              >
                
                <HelpOutlineIcon sx={{ color: "#1C3C6C" }} />
                
              </Tooltip>
              ) : null}
            </Box>
            <CompanyInfo 
              {...companyInfo}
            />
          </Stack>
          <Stack gap="40px">
            <Stack gap="6px">
              <Typography variant="h4" color="custom.textDarkBlue">
                Contacts analysis results
              </Typography>
              {isToogleShow ? (<Box>
                <ToggleSwitch 
                  label="Show enriched data" 
                  checked={showEnrichedData} 
                  onChange={handleToggleChange} 
                />
              </Box>) : (<></>) }
              
            </Stack>
            <Stack gap={3}>
              <Grid container spacing={3}>
                {dataCards.map((item, index) => (
                  <Grid  key={index} size={{ xs: 12, md: 4, sm: 6 }} component="div">
                    <ContactStats 
                      {...item}
                    />
                  </Grid>
                ))}
              </Grid>
            </Stack>
          </Stack>  
          <Stack gap={3}>
            <Typography variant="h4" color="custom.textDarkBlue">
              Percentage of identified members
            </Typography>
            <Box>
              <Tabs
                value={selectedTab}
                onChange={handleChange}
                aria-label="Buying options"
                sx={{
                  borderBottom: "1px solid",
                  borderColor: "#D9D9D9"
                }}
              >
                <Tab 
                  label="Buying committee" 
                  sx={{
                    fontSize: "20px", 
                    fontWeight: 500, 
                    lineHeight: "24px",
                    "&.Mui-selected": { color: "#1C3C6C" },
                  }} 
                />
                <Tab 
                  label="Buying cluster" 
                  sx={{
                    fontSize: "20px", 
                    fontWeight: 500,
                    lineHeight: "24px", 
                    "&.Mui-selected": { color: "#1C3C6C" },
                  }} 
                />
              </Tabs>
              <Box mt={2}>
                {selectedTab === 0 && (
                  <StackedBarChart 
                    {...chartTabData.committee}  
                  />
                )}
                {selectedTab === 1 && (
                  <StackedBarChart 
                    {...chartTabData.cluster}  />
                )}
              </Box>
            </Box>
          </Stack>
          <Stack gap={3}>
            <Typography variant="h4" color="custom.textDarkBlue">
              Buying committee & cluster members identified
            </Typography>
            <Box display="flex" flexDirection="column" justifyContent="space-between">
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Stack  flexDirection="row" gap={2}>
                  <Stack gap={1}>
                    <Typography variant="caption" color="custom.textDarkBlue">View</Typography>
                    <ToggleButtonGroup
                      value={viewTable}
                      exclusive
                      onChange={handleViewChange}
                      aria-label="view toggle"
                      sx={{ height: "40px" }}
                    >
                      <ToggleButton value="table" aria-label="table view"
                      sx={{ width: "55px" }}>
                        <MaterialSymbolsTable active={viewTable === "table"}/>
                      </ToggleButton>
                      <ToggleButton value="chart" aria-label="chart view" sx={{
                          width: "55px"
                      }}>
                        <MageChart active={viewTable === "chart"} />
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Stack>
                  {viewTable === "table" &&
                  <Stack sx={{width:'288px'}} gap={1}>
                    <Typography variant="caption" color="custom.textDarkBlue">Buying committee % range</Typography>
                    <Stack flexDirection="row" gap={2}  alignItems={'center'} paddingTop={'4px'} >
                      <Typography variant="body1" color="custom.textDarkBlue">0</Typography>
                      <Slider
                        value={range}
                        onChange={handleSliderChange}
                        step={5}
                        marks={marks}
                        valueLabelDisplay="auto"
                        min={0}
                        max={100}
                        sx={{
                          color: "#1C3C6C",
                          '& .MuiSlider-rail': {
                            bgcolor: '#D9D9D9',
                          },
                          '& .MuiSlider-thumb': {
                            width: 20,
                            height: 20,
                            
                            '&:focus, &:hover, &.Mui-active': {
                              boxShadow: '0px 0px 0px 8px rgba(0, 0, 255, 0.2)',
                            },
                          },
                          '& .MuiSlider-valueLabel': {
                            width: 41,
                            height: 30,
                            borderRadius: '4px',
                            backgroundColor: '#757575',
                            
                          },
                        }}
                      />
                      <Typography variant="body1" color="custom.textDarkBlue">100</Typography>
                    </Stack>
                  </Stack>
                  }        
                </Stack>
                  {viewTable === "table" ? (
                    <Box mt="auto" display="flex" flexDirection="row" gap={3}>
                      <Typography variant='body1' color='#4C545B'>Imported: {companyInfo.contactsImported}</Typography>
                      <Typography variant='body1' color='#4C545B'>Total: {totalCount}</Typography>
                    </Box>
                  ) : (
                    <Box mt="auto" display="flex" flexDirection="row" gap={3}>
                      <Box display="flex" alignItems="center" gap={2}>
                        <Box
                          sx={{
                            width: 24,
                            height: 20,
                            backgroundColor: "#83AFD3",
                          }}
                        />
                        <Typography variant="body1" color="#1F2932">Buying committee</Typography>
                      </Box>
                      <Box display="flex" alignItems="center" gap={2}>
                        <Box
                          sx={{
                            width: 24,
                            height: 20,
                            backgroundColor: "#0860A8",
                          }}
                        />
                        <Typography variant="body1" color="#1F2932">Buying cluster</Typography>
                      </Box>
                    </Box>
                  )}
              </Box>
              <Box mt={4} width="100%" textAlign="center">
                {viewTable === "table" ? (
                  <DataTable 
                    key={campaignId}
                    columns={columns} 
                    rows={members}
                    totalRows={totalCount}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    sortBy={sortBy}
                    order={order}
                    onQueryChange={handleQueryChange} 
                  />
                ) : (
                  <Box style={{ margin: '0 auto' }}>
                  <ChartTable items={chartData} />
                </Box> 
                )}
              </Box>
            </Box>
          </Stack>
        </Stack>
      ) : (
        <NoDataPlaceholder isCampaignExist={!userData?.campaign_id && !userLoading} />
      )}
    </>
  );
};
