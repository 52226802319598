import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';
import { Stack } from '@mui/system';

interface ContactStatsProps {
  title: string;
  description: string;
  imported?: number;
  enriched?: number;
  icon: SvgIconComponent | React.FC<React.SVGProps<SVGSVGElement>>;
  iconWidth: string;
  iconHeight: string;
}

export const ContactStats: React.FC<ContactStatsProps> = ({
  title,
  description,
  imported,
  enriched,
  icon: Icon,
  iconWidth,
  iconHeight,
}) => {
  return (
    <Paper sx={styles.container}>
      <Box sx={styles.iconBox}>
        <Icon style={{ color: '#1C3C6C', width: iconWidth, height: iconHeight }} />
      </Box>
      <Box justifyContent="space-between">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={styles.titleTypography}
        >
          {title}
        </Typography>
        <Box sx={styles.descriptionBox}>
          <Typography variant="h1" color="custom.textDarkBlue">
            {description}
          </Typography>
          { enriched !== 0 && enriched && (
            <Stack flexDirection="column" gap={1} sx={{ justifyContent: 'center'}}>
              <Stack flexDirection="row" gap="4px">
                <Typography
                  variant="body2"
                  sx={styles.descriptionTypography}
                > 
                Imported:
                </Typography>
                <Typography
                  variant="body2"
                  sx={{lineHeight: "12px", color: '#1C3C6C' }}
                > 
                {imported}
                </Typography>
              </Stack>
             
              <Stack flexDirection="row" gap="4px">
                <Typography
                  variant="body2"
                  sx={styles.descriptionTypography}
                > 
                Enriched:
                </Typography>
                <Typography
                  variant="body2"
                  sx={{lineHeight: "12px", color: '#FE8836' }}
                > 
                {enriched}
                </Typography>
              </Stack>

            </Stack>
            
          )}
        </Box>
      </Box>
    </Paper>
  );
};

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    border: '1px solid',
    borderColor: '#D9D9D9',
    borderRadius: 2,
    p: 2,
    boxShadow: '0px 2px 10px 0px #1976D205',
    width: '100%',
    bgcolor: '#FFFFFF',
  },
  iconBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 76,
    height: 76,
    bgcolor: '#F7F7F7',
    borderRadius: '6px',
  },
  titleTypography: {
    fontSize: '14px',
    lineHeight: '26px',
    letterSpacing: '0.25px',
    fontWeight: 400,
    color: '#1F293299',
  },
  descriptionBox: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
  },
  descriptionTypography: {
    lineHeight: '12px',
    color: '#1F293299',
  },
};
