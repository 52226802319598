import { useEffect, useState } from 'react';
import { useGetCampaignDetailsQuery } from 'api/companyApi';
import { CompanyInfoProps, Summary, SummaryData, UseCompanyDataResult } from 'types/companyTypes';


const defaultCompanyInfo: CompanyInfoProps = {
  name: 'N/A',
  size: '0 - 0 employees',
  contactsImported: '0 contacts',
  industry: '',
};

const defaultSummaryData: SummaryData = {
  committee_members: 0,
  cluster_members: 0,
  engagement_score: 0,
};

const defaultExpectedData: Summary['expected'] = {
  committee_members: 0,
  cluster_members: 0,
  committee_range: {
    min: 0,
    max: 0
  },
  cluster_range: {
    min: 0,
    max: 0
  }
};

export const useCompanyData = (campaignId: string): UseCompanyDataResult => {
  const { data: response, isLoading, isFetching, refetch: campaignRefetch, isUninitialized } = useGetCampaignDetailsQuery(
    { campaign_id: campaignId },
    { skip: !campaignId }
  );

  const [companyInfo, setCompanyInfo] = useState<CompanyInfoProps>(defaultCompanyInfo);
  const [original, setOriginal] = useState<SummaryData>(defaultSummaryData);
  const [enriched, setEnriched] = useState<SummaryData>(defaultSummaryData);
  const [expected, setExpected] = useState<Summary['expected']>(defaultExpectedData);

  useEffect(() => {
    if (response) {
      const { output, input } = response;

      setCompanyInfo({
        name: output.company.name,
        size: (() => {
          const min = output.company.employees_min || 0;
          const max = output.company.employees_max || 0;
      
          if (min === max) {
            return min === 0 ? '-' : `${min}`;
          }
      
          if (min === 0 && max === 0) {
            return '-';
          }
      
          return `${min} - ${max}`;
        })(),
        contactsImported: `${input.total_contacts || '-'}`,
        industry: output.company.industry || '-',
      });

      setOriginal(output.summary.total.original);
      setEnriched(output.summary.total.enriched);
      setExpected(output.summary.expected);
    }
  }, [response]);

  return { companyInfo, original, enriched, expected, isLoading, isFetching, campaignRefetch, isUninitialized };
};
